import { AlertColor } from '@mui/material'
import { proxy } from 'valtio'

/**
 * using valtio
 */
export const notificationProxy = proxy({
  isOpen: false,
  message: '',
  msLevel: 'info' as AlertColor,
  duration: 5000,
})
