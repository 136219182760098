import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { fontWeight600 } from '~/css/font'
import { flex } from '~/modules/AppLayout/FlexGridCss'

const classes = {
  Root: styled.div``,
  Logo: styled.img``,
  Title: styled.div``,
  LineID: styled.div``,
}

const defaultCssset = css`
  &${classes.Root} {
    ${flex.h.allCenter};
    flex-direction: column;
  }

  ${classes.Logo} {
    ${flex.h.allCenter};
    width: 50%;
  }

  ${classes.Title} {
    ${flex.h.allCenter};
    font-size: 22px;
    ${fontWeight600};
  }

  ${classes.LineID} {
    ${flex.h.allCenter};
    font-size: 22px;
    ${fontWeight600};
  }
`

const Display = memo<
  ReactProps<{
    /**
     * @example
     *   src={`asiajye_stock/line.png`}
     */
    src: string

    /**
     * @example
     *   title={`大探極專業團隊`}
     */
    displayTitle: string

    /**
     * @example
     *   displayLineID={`@a957`}
     */
    displayLineID: string
  }>
>(function LineContact(props) {
  return (
    <classes.Root
      className={props.className}
      css={css`
        ${defaultCssset}
      `}
    >
      <classes.Logo
        src={props.src}
        alt='line icon'
      />

      <classes.Title>{props.displayTitle}</classes.Title>

      <classes.LineID>LINE ID：{props.displayLineID}</classes.LineID>
    </classes.Root>
  )
})

export default {
  Display,
  classes,
}
