import { Socket } from '~/modules/SDK/socket2/Socket'
import {
  useExtremeVolumeResource,
  useBig3ConsecutiveResource,
  ActionKey,
  InstitutionKey,
  useZScoreResource,
  PowerKey,
  usePopularResource,
  CrossTypeKey,
  useKdCrossResource,
} from '~/modules/screener/containers/useStockScreenerResource'

/** 爆量篩選 */
export const ExtremeVolumePick = (options: {
  /** 日期 YYYY-MM-DD */
  date: string
  /** N天平均的量 */
  days: number
  /** 成交量篩選門檻 */
  volume: number
  /** 要篩選幾隻標的 按照成交量排名 */
  slice?: number
}) => {
  const { date, days } = options
  const rowdata = useExtremeVolumeResource({ date, days })

  //篩出當日成交量 > N天平均成交量 排行
  const comparedata = rowdata.data?.sort(function (a, b) {
    return a.volume / a.volume_ma > b.volume / b.volume_ma ? -1 : 1
  })

  //過濾成交市值小於N億
  const volume_ = options?.volume ?? 1e8
  const data = comparedata?.filter(item => item.close * 1000 * item.volume > volume_)

  //股票分類-------------------------------------------------------------------------
  //過濾股票面額級距: 全部 或 150以下、150~450、450以上
  //因為股票數量過多 暫時取前6商品

  const sliceCount = options?.slice ?? 6

  const smallCap = data?.filter((item: { close: number }) => item.close < 50).slice(0, sliceCount)
  const midCap = data
    ?.filter((item: { close: number }) => item.close >= 50 && item.close < 100)
    .slice(0, sliceCount)
  const largeCap = data?.filter((item: { close: number }) => item.close >= 100).slice(0, sliceCount)

  //取出symbol number
  const allCapSymbol = data
    ?.slice(0, sliceCount)
    .map((item: { symbol: Socket.SymbolString }) => item.symbol)

  const smallCapSymbol = smallCap?.map((item: { symbol: Socket.SymbolString }) => item.symbol)
  const midCapSymbol = midCap?.map((item: { symbol: Socket.SymbolString }) => item.symbol)
  const largeCapSymbol = largeCap?.map((item: { symbol: Socket.SymbolString }) => item.symbol)
  //--------------------------------------------------------------------------------
  return {
    allCapSymbol,
    smallCapSymbol,
    midCapSymbol,
    largeCapSymbol,
  }
}

/** 三大法人篩選 */
export const Big3ConsecutivePick = (options: {
  /** 日期 YYYY-MM-DD */
  date: string
  /** 連續買賣超天數 */
  days: number
  /** Buy/sell */
  action: ActionKey
  /** Foreigner/trust/dealer */
  institution: InstitutionKey
}) => {
  const { date, days, action, institution } = options
  const rowdata = useBig3ConsecutiveResource({ date, days, action, institution })

  //取出symbol number
  const resultSymbol = rowdata.data?.map((item: { symbol: Socket.SymbolString }) => item.symbol)

  return { resultSymbol }
}

// 強弱篩選
export const useZScorePick = (options: {
  /** 日期 YYYY-MM-DD */
  date: string
  /** 持續天數 */
  days: number
  /** 強-strong、弱-weak選擇 */
  type: PowerKey
  /** 成交量篩選門檻 */
  volume?: number
  /** 價格篩選小 */
  minprice?: number
  /** 價格篩選大 */
  maxprice?: number
  /** 成交金額 */
  amountGte?: number
  /** 檔數 */
  itemLimit?: number
}) => {
  const { date, days, type } = options

  const rowdata = useZScoreResource({
    date,
    days,
    type,
    limit: options.itemLimit,
    amount_gte: options.amountGte,
  })

  //篩出當日成交量 > N天平均成交量 排行
  const comparedata = rowdata.data?.sort(function (a, b) {
    return a.volume > b.volume ? -1 : 1
  })

  const minValue = options?.minprice === undefined ? 0 : options?.minprice
  const maxValue = options?.maxprice === undefined ? 99999 : options?.maxprice

  //過濾成交市值小於N億
  const volume_ = options?.volume ?? 1e8
  const data = comparedata?.filter(item => item.close * 1000 * item.volume > volume_)

  //過濾價格
  const priceFilter = data?.filter(item => item.close > minValue && item.close < maxValue)

  //強度排序
  const strength = priceFilter?.sort(function (a, b) {
    return (options?.type === 'strong' ? a.z_score > b.z_score : a.z_score < b.z_score) ? -1 : 1
  })

  //取出symbol number
  const resultSymbol = strength?.map((item: { symbol: Socket.SymbolString }) => item.symbol)

  return { resultSymbol }
}

//熱門股票排行
export const usePopularPick = (options: {
  /** 日期 YYYY-MM-DD */
  date: string
  /** 排序條件 volume 、 value */
  sort: string
  /** 個股檔數 */
  limit: number
}) => {
  const { date, sort, limit } = options

  const rowdata = usePopularResource({ date, sort, limit })
  const resultSymbol = rowdata?.data?.map((item: { symbol: Socket.SymbolString }) => item.symbol)

  return { resultSymbol }
}

// kd值交叉
export const useCrossKdPick = (options: {
  /** 日期 YYYY-MM-DD */
  date: string
  /** 黃金交叉 | 死亡交叉 */
  cross_type: CrossTypeKey
  /** default 9 */
  fastK?: number
  /** default 3 */
  slowK?: number
  /** default 3 */
  slowD?: number
  /** default 5 */
  days?: number
}) => {
  const { date, cross_type, fastK, slowK, slowD, days } = options

  const { data } = useKdCrossResource({ date, cross_type, fastK, slowK, slowD, days })
  const symbols = data?.symbols.map(item => item)

  return symbols
}
