import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useCallback } from 'react'
import useMedia from '~/hooks/useMedia'
import { store } from '~/pages/heineken_template/_private/store'
import { useElementAsComponent } from '~/pages/heineken_template/_private/useElementAsComponent'
import { clas } from '~/utils/clas'

const classes = {
  Root: styled.div``,
  Button: styled.div``,

  button: {
    Left: styled.div``,
    Right: styled.div``,
  },
}

const cssset = css`
  ${classes} & {
    position: relative;
    height: 100%;
    width: 100%;
  }

  ${classes.Button} {
    display: flex;
    position: absolute;
    z-index: 1;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  ${classes.Button}${classes.button.Left} {
    left: 0;
    top: 50%;
  }

  ${classes.Button}${classes.button.Right} {
    right: 0;
    top: 50%;
  }
`

export const Display = memo<
  ReactProps<
    {
      Button: React.ReactElement | React.ComponentType
    } & (
      | {
          left: boolean
          right?: never
        }
      | {
          left?: never
          right: boolean
        }
    )
  >
>(function Display(props) {
  const { isPc } = useMedia()
  const Button = useElementAsComponent(props.Button)
  const toggleDrawer = () => {
    store.drawerLeft.store.open = false
    store.drawerRight.store.open = false
  }

  return (
    <classes.Root
      className={props.className}
      css={cssset}
    >
      {!isPc && (
        <classes.Button
          className={clas([props.left && classes.button.Left, props.right && classes.button.Right])}
          onClick={event => {
            toggleDrawer()
          }}
        >
          <Button />
        </classes.Button>
      )}

      {props.children}
    </classes.Root>
  )
})

/**
 * - 只在手機視角中顯示
 * - 按了可關閉 Drawer
 */
export default { Display, classes }
