import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { SnackbarMessage } from '~/components/SnackbarWarning'
import { notificationProxy } from '~/modules/notification/notificationProxy'

/**
 * 直接在需要的頁面
 *
 * `<Notification />`
 *
 * `<Notification anchorHorizontal='right'  anchorVertical='top'/>`
 *
 * 對應 store: modules/notification/notificationProxy.tsx
 */
const Notification = memo<
  ReactProps<{ anchorVertical?: 'top' | 'bottom'; anchorHorizontal?: 'right' | 'left' | 'center' }>
>(function Notification(props) {
  const notifyQueueProxyGetter = useSnapshot(notificationProxy)
  const notifyQueueProxySetter = notificationProxy
  const { duration, isOpen, msLevel, message } = notifyQueueProxyGetter

  const handleClose = () => {
    notifyQueueProxySetter.isOpen = false
  }

  return (
    <SnackbarMessage
      anchorOrigin={{
        vertical: props.anchorVertical ?? 'bottom',
        horizontal: props.anchorHorizontal ?? 'right',
      }}
      open={isOpen}
      duration={duration}
      closeDelegate={handleClose}
      message={message}
      severity={msLevel}
    />
  )
})

export default Notification
