import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { store } from '~/pages/heineken_template/_private/store'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'

const classes = {
  Root: styled.div``,
}

const defaultsCssset = css`
  &${classes.Root} {
    ${flex.h.allCenter};
    gap: 4px;
  }
`

export const Display = memo<ReactProps>(function Display(props) {
  return (
    <classes.Root
      className={props.className}
      css={css(defaultsCssset)}
    >
      <ChartingServerSwitch charting={store.charting} />
      <UserAvatarAsDialogButton />
    </classes.Root>
  )
})

export default {
  Display,
  classes,
}
