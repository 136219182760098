import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { store } from '~/pages/heineken_template/_private/store'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'

const classes = {
  Root: styled.div``,
  UserAvatar: styled.div``.withComponent(UserAvatarAsDialogButton),
  user: {
    Root: styled.div``,
    UserName: styled.div``,
    UserCode: styled.div``,
  },
  ChartingServersSwitch: styled.div``.withComponent(ChartingServerSwitch),
}

const defaultsCssset = css`
  &${classes.Root} {
    ${flex.h.allCenter};
  }

  ${classes.user.Root} {
    ${flex.v.allCenter};
    padding: 0px 8px;
  }

  ${TemplateTheme.classes.Light} & {
    ${classes.user.UserName} {
      color: #222222;
      font-size: 14px;
    }

    ${classes.user.UserCode} {
      color: #555555;
      font-size: 12px;
    }
  }

  ${TemplateTheme.classes.Dark} & {
    ${classes.user.UserName} {
      color: #ffffff;
      font-size: 14px;
    }

    ${classes.user.UserCode} {
      color: rgb(170, 170, 170);
      font-size: 12px;
    }
  }
`

const Display = memo<ReactProps>(function Display(props) {
  const username = useMeStore(state => state.meFirebaseState?.name)
  const userCode = useMeStore(state => state.meUserState?.code)

  return (
    <classes.Root
      className={props.className}
      css={css(defaultsCssset)}
    >
      <classes.UserAvatar />
      <classes.user.Root>
        <classes.user.UserName>{username}</classes.user.UserName>
        <classes.user.UserCode>{userCode}</classes.user.UserCode>
      </classes.user.Root>

      <classes.ChartingServersSwitch charting={store.charting} />
    </classes.Root>
  )
})

export default {
  Display,
  classes,
}
